import TutorialCard from 'components/UI/TutorialCard'
import styles from './styles.module.scss'
import SignDocIcon from 'assets/images/icons/sign-doc-icon.svg'
import { useTokenSales } from 'hooks/useTokenSales'
import GlobalPreloader from 'components/UI/GlobalPreloader'
import CustomSelectorUi from 'components/UI/CustomSelectorUi'
import { useState } from 'react'
import UserSerivce from 'API/UserService'
import { useNavigate } from 'react-router-dom'

const SelectRentPropery = () => {
  const { tokenSales, isLoading, error } = useTokenSales()
  const [selectedTokenSale, setSelectedTokenSale] = useState(null)
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  if (isLoading) {
    return <GlobalPreloader />
  }

  if (error) {
    return <div>
      Error:
      {' '}
      {error}
    </div>
  }

  const onSelectTokenSale = (tokeSale) => {
    setSelectedTokenSale(tokeSale)
  }
  const fundedTokenSales = tokenSales.items.filter((tokenSale) => ['funded', 'default_proposals_created'].includes(tokenSale.status) && tokenSale.has_tenant === false)

  const handleRentProperty = async () => {
    setLoading(true)

    try {
      const response = await UserSerivce.addTenant(selectedTokenSale.id)
      console.log(response)
      navigate(`/rent-payment/${selectedTokenSale.id}`)
    } catch (error) {
      console.log(error)
    }finally {
      setLoading(false)
    }
  }

  return (
    <div>
      <TutorialCard>
        <div className={styles.iconWrapper}>
          <img src={SignDocIcon} alt="" />
        </div>
        <h4>Select a property</h4>
        <p>Select the property in which you live and for which you will pay rent. If there is no such object, then write to project support</p>
        <CustomSelectorUi onSelectTokenSale={onSelectTokenSale} tokenSales={fundedTokenSales} />

        {selectedTokenSale &&
          <div onClick={handleRentProperty} className={styles.verifyBtn}>
            {loading ? 'Updating' : 'Yes, I live here'}
          </div>}
      </TutorialCard>
    </div>
  )
}

export default SelectRentPropery