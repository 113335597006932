import { useState, useEffect, useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper/modules'
import { useWeb3React } from '@web3-react/core'

import FormNavigation from './FormNavigation/FormNavigation'
import NotStarted from './NotStarted'
import {
  useUpdateProposals,
  useUserProposals,
  useUserVotes,
} from 'state/user/hooks'
import styles from './Governance.module.scss'
import { useGovernance } from 'hooks/useGovernance'
import { Link } from 'react-router-dom'

/*
  STEPS:
  1- token_address will be passed as a query parameter
  2- check if token_address has active proposals
  3- if yes, check if user is eligible to vote
  4- if yes, show the survey form with questions
  5- on submit, validate form + call the smart contract to delegate and vote
*/

const Governance = () => {
  const [searchParams] = useSearchParams()
  const { account } = useWeb3React()
  const [proposalLoading, setProposalLoading] = useState(true)
  const [started, setStarted] = useState(false)
  const [step, setStep] = useState(1)
  const [isEligible, setIsEligible] = useState(false)
  const [error, setError] = useState(null)
  const [checkingEligibility, setCheckingEligibility] = useState(true)
  const {
    register,
    formState,
    handleSubmit,
    setError: setFormError,
  } = useForm()
  const updateProposals = useUpdateProposals()
  const votes = useUserVotes()

  useEffect(() => {
    updateProposals().then(() => {
      setProposalLoading(false)
    })
  }, [updateProposals])

  const proposals = useUserProposals()

  const votedProposals = useMemo(() => {
    return votes?.map((v) => v.proposal_chain_id) || []
  }, [votes])

  const activeProposals = useMemo(() => {
    return (
      proposals[searchParams.get('token_address')]
        ?.filter((p) => p.proposal_state === 'active')
        .filter((p) => !votedProposals.includes(p.proposal_id)) || []
    )
  }, [proposals, searchParams, votedProposals])
  console.log('activeProposals', activeProposals)

  const { delegate, vote } = useGovernance(
    searchParams.get('token_address'),
    activeProposals?.[0]?.governor_address,
  )

  const handleStart = useCallback(() => {
    setStarted(true)
  }, [])

  const handleNextClick = useCallback(() => {
    if (step < activeProposals.length) {
      const nextButton = document.querySelector('.governance-next-button')
      nextButton?.click()
    }
  }, [step, activeProposals])

  const onSubmit = useCallback(
    async (data) => {
      // STEP 5: call the smart contract to delegate and vote
      console.log('submitting', data)
      try {
        if (Object.keys(data).length !== activeProposals.length) {
          // return setSubmitError('Please answer all questions.')
          throw new Error('Please answer all questions.')
        }

        if (Object.values(data).some((v) => !['0', '1', '2'].includes(v))) {
          const invalidProposalsIndexes = Object.entries(data)
            // eslint-disable-next-line no-unused-vars
            .filter(([_, v]) => !['0', '1', '2'].includes(v))
            .map((_, i) => i + 1)
          throw new Error(
            `Invalid answer for question ${invalidProposalsIndexes}.`,
          )
          // return
        }
        await delegate(account)

        await Promise.all(
          activeProposals.map(async (q) => {
            return vote(q.proposal_id, parseInt(data[q.proposal_id]))
          }),
        )
      } catch (error) {
        console.error(error)
        const userRejected = error.message.includes('rejected transaction')
        const message = userRejected
          ? 'User rejected the transaction.'
          : error.message ||
            'There was an error submitting your vote. Please try again later.'
        setFormError('root', { message })
      }
    },
    [activeProposals, account, delegate, vote],
  )

  useEffect(() => {
    // listen for enter key press to start the survey
    const handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        !started && handleStart()
        started && handleNextClick()
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [started, activeProposals])

  useEffect(() => {
    // STEP 3: check if user is eligible to vote
    const tokenAddress = searchParams.get('token_address')
    if (!tokenAddress) {
      setCheckingEligibility(false)
      setIsEligible(false)
      setError('Token address is required.')
    } else {
      if (proposalLoading) return
      if (tokenAddress in proposals) {
        setCheckingEligibility(false)
        setIsEligible(true)
        setError(null)
      } else {
        setCheckingEligibility(false)
        setIsEligible(false)
        setError(
          'Sorry, you are not eligible to vote. You must hold the token.',
        )
      }
    }
  }, [searchParams, proposals, proposalLoading])

  if (checkingEligibility || proposalLoading) {
    return (
      <div className={styles.container}>
        <style>
          {`
          body {
            background-color: #fff;
          }
          div:has(footer) {
            margin: 0 !important;
          }
          footer {
            display: none;
          }
        `}
        </style>
        <div className={styles.loader}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M21 12a9 9 0 1 1-6.219-8.56" />
          </svg>
          <span>Loading your proposals...</span>
        </div>
      </div>
    )
  }

  if (!isEligible) {
    return (
      <div className={styles.container}>
        <style>
          {`
          body {
            background-color: #fff;
          }
          div:has(footer) {
            margin: 0 !important;
          }
          footer {
            display: none;
          }
        `}
        </style>
        <div className={styles.error}>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <circle cx="12" cy="12" r="10" />
              <path d="m4.9 4.9 14.2 14.2" />
            </svg>
          </span>
          <span>{error}</span>
        </div>
      </div>
    )
  }

  if (activeProposals.length === 0) {
    return (
      <div>
        <style>
          {`
          body {
            background-color: #fff;
          }
          div:has(footer) {
            margin: 0 !important;
          }
          footer {
            display: none;
          }
        `}
        </style>
        <div className={styles.success}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M21 10.5V19a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h12.5" />
            <path d="m9 11 3 3L22 4" />
          </svg>
          <h1>There are no active proposals to vote on.</h1>
          <p>
            Please check back later for new proposals. If you have any questions
            or need assistance, please contact support.
          </p>
          <Link to="/wallet" className={styles.button}>
            Back to Wallet
          </Link>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <style>
        {`
          body {
            background-color: #fff;
          }
          div:has(footer) {
            margin: 0 !important;
          }
          footer {
            display: none;
          }
        `}
      </style>

      {!started && <NotStarted handleStart={handleStart} />}

      {started && formState.isSubmitSuccessful && (
        <div className={styles.success}>
          <h1>Thank you for completing the survey!</h1>
          <p>
            Your vote has been successfully recorded and will take effect once
            the token sale is complete.
          </p>

          <Link to="/wallet" className={styles.button}>
            Back to Wallet
          </Link>
        </div>
      )}

      {started && !formState.isSubmitSuccessful && (
        <form onSubmit={handleSubmit(onSubmit)} style={{ flex: 1 }}>
          <FormNavigation />
          <Swiper
            modules={[Navigation, Pagination]}
            navigation={{
              nextEl: '.governance-next-button',
              prevEl: '.governance-back-button',
            }}
            pagination={{
              el: '.governance-pagination',
              clickable: true,
            }}
            onSlideChange={(swiper) => {
              setStep(swiper.activeIndex + 1)
            }}
            spaceBetween={0}
            slidesPerView={1}
          >
            {activeProposals.length > 0 &&
              activeProposals.map((q, i) => (
                <SwiperSlide key={q.proposal_id}>
                  <div className={styles.slide}>
                    <div>
                      <h2>{q.description}</h2>
                      <div className={styles.radioGroup}>
                        <label>
                          <input
                            type="radio"
                            value="1"
                            {...register(q.proposal_id, {
                              required: {
                                value: true,
                                message:
                                  'Please answer Question ' + (i + 1) + '.',
                              },
                              pattern: {
                                value: /[0-2]/,
                                message:
                                  'Please answer Question ' + (i + 1) + '.',
                              },
                            })}
                          />
                          <span>Yes</span>
                        </label>
                        <label>
                          <input
                            type="radio"
                            value="0"
                            {...register(q.proposal_id, {
                              required: {
                                value: true,
                                message:
                                  'Please answer Question ' + (i + 1) + '.',
                              },
                              pattern: {
                                value: /[0-2]/,
                                message:
                                  'Please answer Question ' + (i + 1) + '.',
                              },
                            })}
                          />
                          <span>No</span>
                        </label>
                        <label>
                          <input
                            type="radio"
                            value="2"
                            {...register(q.proposal_id, {
                              required: {
                                value: true,
                                message:
                                  'Please answer Question ' + (i + 1) + '.',
                              },
                              pattern: {
                                value: /[0-2]/,
                                message:
                                  'Please answer Question ' + (i + 1) + '.',
                              },
                            })}
                          />
                          <span>Abstain</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>

          <div className={styles.buttons}>
            {step < activeProposals.length && (
              <button
                onClick={handleNextClick}
                type="button"
                className={`${styles.button} next`}
              >
                Next
              </button>
            )}
            {step === activeProposals.length && (
              <button
                type="submit"
                className={`${styles.button} submit`}
                disabled={formState.isSubmitting}
              >
                {formState.isSubmitting ? 'Submitting...' : 'Submit'}
              </button>
            )}
          </div>
          {Object.values(formState.errors).length > 0 && (
            <p className={styles.formError}>
              {Object.entries(formState.errors).map(([key, value]) => (
                <span key={key}>{value.message}</span>
              ))}
            </p>
          )}
        </form>
      )}

      <p
        style={{
          marginBottom: '4rem',
        }}
      >
        © 2024 EdificeX. All rights reserved.
      </p>
    </div>
  )
}

export default Governance
