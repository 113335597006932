import { useCallback, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { ethers } from 'ethers'
import BigNumber from 'bignumber.js'

import { EXCHANGE_ADDRESS } from 'constants'

import { formatNumber } from 'utils/formatNumber'

import { useRealEstateTokenContract, useAddressRegistryContract } from './useContract'
import useCatchTxError from './useCatchTxError'
import useInterval from './useInterval'

export function useTokenSaleSuccessed(address) {
  const contract = useRealEstateTokenContract(address)
  const [saleSuccessed, setSaleSuccessed] = useState(false)

  const update = useCallback(async () => {
    if (!contract) {
      setSaleSuccessed(false)
      return
    }

    const tokensaleStates = await contract.tokensaleStates()
    const successed = tokensaleStates.successed
    setSaleSuccessed(successed)
  }, [contract])

  useInterval(update, 3000)

  return saleSuccessed
}

export function useTokenBalance(address) {
  const { account } = useWeb3React()
  const contract = useRealEstateTokenContract(address)
  const [balance, setBalance] = useState('0')

  const update = useCallback(async () => {
    if (!account || !contract) {
      setBalance('0')
      return
    }

    const val = await contract.balanceOf(account)
    setBalance(val.toString())
  }, [contract, account])

  useInterval(update, 3000)

  return balance
}

export function useIsTokenSaleInvestor(tokenAddress) {
  const { account } = useWeb3React()
  const contract = useRealEstateTokenContract(tokenAddress)
  const [isInvestor, setIsInvestor] = useState(false)

  const update = useCallback(async () => {
    if (!account || !contract) {
      setIsInvestor(false)
      return
    }

    const val = await contract.investorAmount(account)
    setIsInvestor(val > 0n)
  }, [contract, account])

  useInterval(update, 3000)

  return isInvestor
}

export function useTokenExchangeAllowance(address) {
  const { account, chainId } = useWeb3React()
  const contract = useRealEstateTokenContract(address)
  const [allowance, setAllowance] = useState('0')

  const update = useCallback(async () => {
    if (!account || !contract || !EXCHANGE_ADDRESS[chainId]) {
      setAllowance('0')
      return
    }

    const val = await contract.allowance(account, EXCHANGE_ADDRESS[chainId])
    setAllowance(val.toString())
  }, [contract, account, chainId])

  useInterval(update, 3000)

  return allowance
}

export function useTokenExchangeApprove(address, symbol) {
  const { account, chainId } = useWeb3React()
  const contract = useRealEstateTokenContract(address)
  const { loading, fetchWithCatchTxError } = useCatchTxError()

  const onApprove = useCallback(async () => {
    if (!account || !contract || !EXCHANGE_ADDRESS[chainId]) {
      return
    }

    const receipt = await fetchWithCatchTxError(() => {
      return contract.approve(EXCHANGE_ADDRESS[chainId], ethers.MaxUint256)
    }, {
      message: `Approving ${symbol} token to Exchange contract`,
    })

    return receipt
  }, [account, contract, chainId])

  return { loading, onApprove }
}

export function useBuyTokens(address, symbol) {
  const contract = useRealEstateTokenContract(address)
  const { loading, fetchWithCatchTxError } = useCatchTxError()

  const onBuyTokens = useCallback(async (amount, price, becomeBorrower) => {
    if (!contract) {
      return
    }

    const amt = new BigNumber(amount)
    const prc = new BigNumber(price)
    const dnrValue = amt.times(prc).div(new BigNumber(1e18))
    const receipt = await fetchWithCatchTxError(() => {
      return contract.buy(becomeBorrower, { value: BigInt(dnrValue.toFixed(0)) })
    }, {
      message: `Buying ${formatNumber(amount, 2, 18)} ${symbol} tokens`,
    })

    return receipt
  }, [contract, fetchWithCatchTxError])

  return { loading, onBuyTokens }
}

export function useBuyTokensEstimate(address) {
  const { account } = useWeb3React()
  const addressRegistryContract = useAddressRegistryContract()
  const contract = useRealEstateTokenContract(address)

  const estimateBuyTokens = useCallback(async (dnrAmount, becomeBorrower) => {
    if (!contract || !addressRegistryContract) {
      return BigInt(0)
    }

    const isWhitelisted = await addressRegistryContract.isWhitelisted(account)
    if (!isWhitelisted) {
      console.log('Address not whitelisted')
      return BigInt(0)
    }

    try {
      const val = await contract.estimateGas.buy(becomeBorrower, { value: dnrAmount })

      return BigInt(new BigNumber(val.toString()).times(new BigNumber('2000000000')).toString())
    } catch (error) {
      return BigInt(0)
    }
  }, [account, contract, addressRegistryContract])

  return estimateBuyTokens
}

export function useDepositRent(address) {
  const contract = useRealEstateTokenContract(address)
  const { loading, fetchWithCatchTxError } = useCatchTxError()

  const onDepositRent = useCallback(async (amount) => {
    if (!contract) {
      return
    }

    const receipt = await fetchWithCatchTxError(() => {
      return contract.depositRent({ value: amount })
    }, {
      message: 'Depositing rent',
    })

    return receipt
  }, [contract, fetchWithCatchTxError])

  return { loading, onDepositRent }
}

export function useDepositMusharakah(address, borrowerAddress) {
  const contract = useRealEstateTokenContract(address)
  const { loading, fetchWithCatchTxError } = useCatchTxError()

  const onDepositMusharakah = useCallback(async (amount) => {
    if (!contract) {
      return
    }

    const mushState = await contract.musharakahState()
    const contractBorrower = mushState.mushBorrower
    if (contractBorrower.toLowerCase() != borrowerAddress.toLowerCase()) {
      return
    }

    const receipt = await fetchWithCatchTxError(() => {
      return contract.depositMusharakah({ value: amount })
    }, {
      message: 'Depositing Musharakah',
    })

    return receipt
  }, [contract, borrowerAddress, fetchWithCatchTxError])

  return { loading, onDepositMusharakah }
}

export function useSetSellingMusharakah(tokenAddress, borrowerAddress) {
  const contract = useRealEstateTokenContract(tokenAddress)
  const { loading, fetchWithCatchTxError } = useCatchTxError()

  const onSetSellingMusharakah = useCallback(async (selling) => {
    if (!contract) {
      return
    }

    const mushState = await contract.musharakahState()
    const contractBorrower = mushState.mushBorrower
    if (contractBorrower.toLowerCase() != borrowerAddress.toLowerCase()) {
      return
    }

    const receipt = await fetchWithCatchTxError(() => {
      return contract.setSellingMusharakah(selling)
    }, {
      message: selling ? 'Selling borrower shares' : 'Cancelling borrower shares sell',
    })

    return receipt
  }, [contract, borrowerAddress, fetchWithCatchTxError])

  return { loading, onSetSellingMusharakah }
}

export function useShareOrderExists(selectedToken, userShareOrders) {
  const [shareOrderExists, setShareOrderExists] = useState(false)

  const updateShareOrderExists = useCallback(() => {
    // Функция проверки существования активного ордера
    const exists = userShareOrders.some(order =>
      order.status === 'active' && order.token_info.contract_address === selectedToken,
    )
    setShareOrderExists(exists)
  }, [userShareOrders, selectedToken])

  // Устанавливаем интервал для проверки
  useInterval(updateShareOrderExists, 3000) // Проверяем каждые 3 секунды

  return shareOrderExists
}

export function useBuyMusharakah(tokenAddress) {
  const contract = useRealEstateTokenContract(tokenAddress)
  const { loading, fetchWithCatchTxError } = useCatchTxError()

  const onBuyMusharakah = useCallback(async (amount) => {
    if (!contract) {
      return
    }

    const receipt = await fetchWithCatchTxError(() => {
      return contract.buyMusharakah({value: amount})
    }, {
      message: 'Buying musharakah',
    })

    return receipt
  }, [contract, fetchWithCatchTxError])

  return { loading, onBuyMusharakah }
}