import { useWeb3React } from '@web3-react/core'
import useCatchTxError from './useCatchTxError'
import {
  useGovernanceContract,
  useRealEstateTokenContract,
} from './useContract'

export const useGovernance = (tokenAddress, governanceAddress) => {
  const { loading, fetchWithCatchTxError } = useCatchTxError()
  const { account } = useWeb3React()
  const tokenContract = useRealEstateTokenContract(tokenAddress)
  const governanceContract = useGovernanceContract(governanceAddress)

  const delegate = async (delegatee) => {
    if (!account || !tokenContract || !delegatee) {
      console.error('account, tokenContract or delegatee is not ready')
      return
    }

    // call the contract method to delegate
    const receipt = await fetchWithCatchTxError(
      () => {
        return tokenContract.delegate(delegatee)
      },
      {
        message: 'Delegating',
      },
    )

    return receipt
  }

  const vote = async (proposal_id, vote) => {
    if (!account || !governanceContract) {
      // TODO MA UI: show error message
      console.error('account or contract is not ready')
      return
    }

    if (typeof vote !== 'number' || [0, 1, 2].indexOf(vote) === -1) {
      // TODO MA UI: show error message
      console.error('invalid vote')
      return
    }

    // call the contract method to vote
    const receipt = await fetchWithCatchTxError(
      () => {
        return governanceContract.castVote(proposal_id, vote)
      },
      {
        message: 'Voting on proposal ' + proposal_id,
      },
    )

    return receipt
  }

  return {
    loading,
    delegate,
    vote,
  }
}
