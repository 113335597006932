import styles from './Governance.module.scss'

const NotStarted = ({ handleStart }) => {
  return (
    <div className={styles.notStarted}>
      <div className={styles.logo}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="33"
          height="32"
          viewBox="0 0 33 32"
          fill="none"
        >
          <path
            d="M17.8334 2.6665L5.95796 16.917C5.49288 17.4751 5.26034 17.7541 5.25679 17.9898C5.2537 18.1947 5.34499 18.3896 5.50436 18.5184C5.68768 18.6665 6.05092 18.6665 6.7774 18.6665H16.5L15.1667 29.3332L27.0421 15.0827C27.5072 14.5246 27.7397 14.2456 27.7433 14.0099C27.7463 13.805 27.655 13.6101 27.4957 13.4813C27.3124 13.3332 26.9491 13.3332 26.2226 13.3332H16.5L17.8334 2.6665Z"
            stroke="#FF6F89"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>

      <h1>
        Welcome to the EdificeX Community, where we manage properties and tokens
        through our collective efforts.
      </h1>

      <p>
        We’re excited to have you as a property owner with EdificeX! Please take
        a moment to participate in this survey to help guide important property
        management decisions. Decisions will be made based on a majority vote of
        more than 50%, and the property manager will automatically implement the
        chosen options.
        <br />
        After completing this survey, you will gain access to your EdificeX
        token wallet, investment dashboard, rental income withdrawals, and more.
      </p>
      <div className={styles.startAction}>
        <button onClick={handleStart}>
          <span>Begin</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
          >
            <path
              d="M10.5 12.5L8.00004 10M10.5 12.5C11.6641 12.0573 12.7808 11.499 13.8334 10.8334M10.5 12.5V16.6667C10.5 16.6667 13.025 16.2084 13.8334 15C14.7334 13.65 13.8334 10.8334 13.8334 10.8334M8.00004 10C8.44349 8.84957 9.00188 7.74676 9.66671 6.70838C10.6377 5.15587 11.9897 3.87758 13.5942 2.99512C15.1987 2.11266 17.0023 1.65535 18.8334 1.66671C18.8334 3.93338 18.1834 7.91671 13.8334 10.8334M8.00004 10H3.83337C3.83337 10 4.29171 7.47504 5.50004 6.66671C6.85004 5.76671 9.66671 6.66671 9.66671 6.66671M4.25004 13.75C3.00004 14.8 2.58337 17.9167 2.58337 17.9167C2.58337 17.9167 5.70004 17.5 6.75004 16.25C7.34171 15.55 7.33337 14.475 6.67504 13.825C6.35113 13.5159 5.92445 13.3372 5.4769 13.3234C5.02934 13.3096 4.59244 13.4615 4.25004 13.75Z"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <p>press Enter ↵</p>
      </div>
    </div>
  )
}

export default NotStarted
