import api from './api'

const ProposalService = {
  proposals: async (token_address) => {
    const { data } = await api.get('/proposals', {
      params: {
        token_address,
      },
    })
    return data.data
  },
  votes: async (user_id) => {
    if (!user_id) return []
    const { data } = await api.get('/proposals/uservotes', {
      params: {
        user_id,
      },
    })
    return data.data
  },
}

export default ProposalService
