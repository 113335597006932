import { useCallback, useState, useEffect, useMemo } from 'react'
import moment from 'moment'

import TokenSaleService from 'API/TokenSaleService'
import { useDepositRent } from 'hooks/useRealEstateToken'
// import GlobalPreloader from 'components/UI/GlobalPreloader'
import DepositTransaction from 'components/DepositTransaction'

import { formatBlockchainAddress } from 'utils/formatAddress'
import { formatNative } from 'utils/formatNumber'

import styles from './styles.module.scss'
import GlobalPreloader from 'components/UI/GlobalPreloader'

const RentPaymentsItem = ({tokenSale}) => {

  // const tokenSale = tokenSales[0]
  const { onDepositRent, loading: processing } = useDepositRent(tokenSale?.token_info.contract_address)

  const [rentError, setRentError] = useState('')

  const [depositTransactions, setDepositTransactions] = useState([])

  const [
    firstPaymentDate,
    lastPaymentDate,
    totalPaid,
  ] = useMemo(() => {
    if (depositTransactions.length === 0) {
      return [null, null, 0]
    }
    return [
      depositTransactions[depositTransactions.length - 1].created_at,
      depositTransactions[0].created_at,
      depositTransactions.reduce((sum, tx) => sum + BigInt(tx.data.amount), 0n),
    ]
  }, [depositTransactions])

  const fetchDepositTransactions = useCallback(async () => {
    if (!tokenSale?.id) {
      return
    }

    const txs = await TokenSaleService.fetchDepositRentTransactions(tokenSale.id)
    setDepositTransactions(txs)
  }, [tokenSale?.id])

  useEffect(() => {

    fetchDepositTransactions()
  }, [fetchDepositTransactions])

  const handleDepositRent = useCallback(async () => {
    if (!tokenSale) {
      return
    }

    try {
      await onDepositRent(tokenSale.rent_amount)
      fetchDepositTransactions()
    } catch (error) {
      setRentError(JSON.stringify(error?.data?.message))
      console.log('Rejected', error?.data?.message)
    }
  }, [tokenSale?.rent_amount, onDepositRent, fetchDepositTransactions])

  if (!tokenSale) {
    return (
      <div className={styles.wrapper}>
        You do not have any property on rent
      </div>
    )
  }

  return (
    <div key={tokenSale.id} className={styles.wrapper}>
      {processing && <GlobalPreloader />}
      <div className={styles.propertyInfo}>
        <div className={styles.leftSection}>
          <div className={styles.thumbWrapper}>
            <img src={tokenSale.thumbnail?.url} alt="" />
          </div>
          <div className={styles.tokensaleInfo}>
            <p className={styles.idTxt}>{`ID: ${tokenSale.id}`}</p>
            <p className={styles.titleTxt}>{tokenSale.title}</p>
            <p className={styles.adddressTxt}>{`${tokenSale.address.address}, ${tokenSale.address.city}, ${tokenSale.address.country}`}</p>
          </div>
        </div>
        <div className={styles.rightSection}>
          <p className={styles.rentAmount}>
            <span className={styles.description}>Amount to pay rent:</span>
            {` ${formatNative(tokenSale.rent_amount)} DNR`}
          </p>
          {rentError && <p className={styles.errorLabel}>{rentError}</p>}
          <button disabled={processing || !['funded', 'default_proposals_created'].includes(tokenSale.status)} className={styles.payBtn} onClick={handleDepositRent}>
            Pay rent
          </button>
        </div>
      </div>
      {/* <div className={styles.transactionsSection}><p className={styles.noneTransactions}>You do not have any payment. Make your first payment to see the transaction</p></div> */}
      <div className={styles.detailsSection}>
        <div className={styles.transactionsSection}>
          {depositTransactions.length === 0 && 'You don not have any transactions yet'}
          {depositTransactions.map(tx => (
            <DepositTransaction key={tx.hash} transaction={tx} />
          ))}
        </div>
        <div className={styles.sidebar}>
          <div className={styles.statsItem}>
            <div className={styles.statsTitle}>Status</div>
            <div className={styles.statsValue}>Active</div>
          </div>
          <div className={styles.statsItem}>
            <div className={styles.statsTitle}>Total paid</div>
            <div className={styles.statsValue}>
              {`${formatNative(totalPaid)} DNR`}
            </div>
          </div>
          <div className={styles.statsItem}>
            <div className={styles.statsTitle}>Total number of payments</div>
            <div className={styles.statsValue}>
              {depositTransactions.length}
            </div>
          </div>
          <div className={styles.statsItem}>
            <div className={styles.statsTitle}>First payment date</div>
            <div className={styles.statsValue}>
              {firstPaymentDate ? moment(firstPaymentDate).format('DD MMMM, YYYY') : '-'}
            </div>
          </div>
          <div className={styles.statsItem}>
            <div className={styles.statsTitle}>Last payment date</div>
            <div className={styles.statsValue}>
              {lastPaymentDate ? moment(lastPaymentDate).format('DD MMMM, YYYY') : '-'}
            </div>
          </div>
          <div className={styles.statsItem}>
            <div className={styles.statsTitle}>Smart Contract</div>
            <div className={styles.statsValue}>
              <a href={`https://explorer-testnet.edifice.network/address/${tokenSale.token_info.contract_address}`} target="_blank" rel="noopener noreferrer">
                {`${formatBlockchainAddress(tokenSale.token_info.contract_address)}`}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RentPaymentsItem