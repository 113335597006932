import styles from './styles.module.scss'
import ProgressBar from '../UI/ProgressBar'
import { NavLink } from 'react-router-dom'
import noPhoto from '../../assets/images/noPhoto.png'

import { formatNumber, formatNative } from 'utils/formatNumber'
import { convertTokensToDNR } from 'utils/calculate'

const TokenSaleCard = ({ tokenSale, status, isMusharakah = false }) => {
  const getRemainingDays = (date) => {
    const endDate = new Date(date)
    const currentDate = new Date()
    const timeDiff = endDate - currentDate
    const daysRemaining = Math.ceil(timeDiff / (1000 * 60 * 60 * 24))
    return daysRemaining
  }

  const totalSupply = BigInt(tokenSale.token_info.total_supply)
  const tokensLeft = BigInt(tokenSale.token_info.musharakah_tokens_left)
  const price = BigInt(tokenSale.token_info.price)
  const minBuyAmount = BigInt(tokenSale.token_info.min_buy_amount)
  const statusReady = ['funded', 'default_proposals_created'].includes(tokenSale.status)

  return (
    <div className={styles.wrapper}>
      {/* <div className={styles.favLabel}>
        <img src={HeartIcon} alt="heart-icon" />
      </div> */}

      <div className={styles.thumbnail}>
        {isMusharakah && (
          <div className={styles.musharakahLabel}>
            Musharakah smart contract
          </div>
        )}

        <p className={styles.daysLeft}>
          {tokenSale.status === 'active' && !tokenSale.token_info.selling_musharakah &&
          `${getRemainingDays(tokenSale.token_info.sale_end_at)} days left`}
          {tokenSale.status === 'upcoming' && !tokenSale.token_info.selling_musharakah &&
            `${getRemainingDays(
              tokenSale.token_info.sale_end_at,
            )} days before launch`}
          {tokenSale.status === 'ended' && 'ended'}
        </p>
        {tokenSale.thumbnail ? (
          <img src={tokenSale.thumbnail.url} alt={tokenSale.slug} />
        ) : (
          <img src={noPhoto} alt={tokenSale.slug} />
        )}
      </div>
      <div className={styles.name}>{tokenSale.title}</div>
      <div className={styles.address}>
        {tokenSale.address.address}
        ,
        {' '}
        {tokenSale.address.city}
        ,
        {' '}
        {tokenSale.address.country}
      </div>
      {status === 'active' && (
        <ProgressBar total={tokenSale.token_info.hardcap} current={tokenSale.token_info.total_supply} />
      )}
      <div className={styles.additionalInfo}>
        <div className={styles.infoItem}>
          <p className={styles.title}>Projected Rental Yield</p>
          <p className={styles.amount}>
            {tokenSale.additional_info.rental_yield}
            %
          </p>
        </div>
        <div className={styles.infoItem}>
          <p className={styles.title}>Projected Annual Return</p>
          <p className={styles.amount}>
            {tokenSale.additional_info.projected_annual_return}
            %
          </p>
        </div>
        <div className={styles.infoItem}>
          <p className={styles.title}>Projected Annual Return</p>
          <p className={styles.amount}>
            {tokenSale.additional_info.projected_rental_yield}
            %
          </p>
        </div>

        <div className={styles.infoItem}>
          <p className={styles.title}>Minimum amount</p>
          {
            tokenSale.token_info.selling_musharakah
              ? (<p className={styles.amount}>
                {`${formatNumber(convertTokensToDNR(totalSupply - tokensLeft, price), 2, 18)} DNR`}
              </p>)
              : (<p className={styles.amount}>
                {`${formatNumber(convertTokensToDNR(minBuyAmount, price), 2, 18)} DNR`}
              </p>)
          }

        </div>
      </div>
      {!statusReady && (<div className={styles.available}>
        Available:
        {' '}
        {formatNative(parseInt(tokenSale.token_info.hardcap) - parseInt(tokenSale.token_info.total_supply))}
      </div>)}

      {statusReady && tokenSale.token_info.selling_musharakah && (<div className={styles.available}>
        Available:
        {' '}
        {formatNative(tokenSale.token_info.total_supply - tokenSale.token_info.musharakah_tokens_left)}
      </div>)}

      {tokenSale.status === 'active' && !tokenSale.token_info.selling_musharakah && (
        <NavLink to={`/tokensale/${tokenSale.id}`} className={styles.btn}>
          Buy token
        </NavLink>
      )}

      {statusReady && tokenSale.token_info.selling_musharakah && (
        <NavLink to={`/tokensale/${tokenSale.id}`} className={styles.btn}>
          Become an owner
        </NavLink>
      )}

      {tokenSale.status === 'upcoming' && (
        <NavLink to={`/tokensale/${tokenSale.id}`} className={styles.btn}>
          More Info
        </NavLink>
      )}
    </div>
  )
}

export default TokenSaleCard
