import { formatNative } from 'utils/formatNumber'

import styles from './styles.module.scss'

const ProgressBar = ({ total, current }) => {
  const percentage = total > 0 ? ((current / total) * 100).toFixed(3) : 0

  return (
    <div className={styles.progressBarContainer}>
      <div className={styles.progressBarBackground}>
        <div
          className={styles.progressBarFill}
          style={{ width: `${percentage}%` }}
        />
      </div>
      <div className={styles.progressBarText}>
        <span className={styles.percentage}>
          {percentage}
          %
        </span>
        <span className={styles.data}>
          {formatNative(current)}
          {' '}
          /
          {' '}
          <span className={styles.total}>
            {formatNative(total)}
          </span>
        </span>
      </div>
    </div>
  )
}

export default ProgressBar