import { createSlice } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const initialState = {
  authToken: null,
  balanceNative: '0',
  details: null,
  transactions: [],
  orders: [],
  proposals: [],
  sellSharesOrders: [],
  musharakahTokenSales: [],
  tenementTokenSales: [],
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAuthToken(state, action) {
      state.authToken = action.payload
      if (action.payload === null) {
        state.balanceNative = '0'
        state.details = null
        state.transactions = []
        state.musharakahTokenSales = []
        state.tenementTokenSales = []
        state.orders = []
        state.sellSharesOrders = []
      }
    },
    setUserDetails(state, action) {
      state.details = action.payload
    },
    setUserBalanceNative(state, action) {
      state.balanceNative = action.payload
    },
    setUserETHAddress(state, action) {
      state.details.eth_address = action.payload
    },
    setUserTransactions(state, action) {
      state.transactions = action.payload
    },
    setUserOrders(state, action) {
      state.orders = action.payload
    },
    setUserSellSharesOrders(state, action) {
      state.sellSharesOrders = action.payload
    },
    setUserMusharakahTokenSales(state, action) {
      state.musharakahTokenSales = action.payload
    },
    setUserTenementTokenSales(state, action) {
      state.tenementTokenSales = action.payload
    },
    setUserProposals(state, action) {
      state.proposals = action.payload
    },
    setUserVotes(state, action) {
      state.votes = action.payload
    },
  },
})

export const {
  setAuthToken,
  unsetAuthToken,
  setUserDetails,
  setUserBalanceNative,
  setUserETHAddress,
  setUserTransactions,
  setUserOrders,
  setUserSellSharesOrders,
  setUserMusharakahTokenSales,
  setUserTenementTokenSales,
  setUserProposals,
  setUserVotes,
} = userSlice.actions

export default persistReducer({
  key: 'user',
  storage,
  whitelist: ['authToken'],
}, userSlice.reducer)
