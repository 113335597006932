import styles from './styles.module.scss'
import TokenProposals from './TokenProposals'


/*
  proposalsProps = {
   token_address: [...proposals]
  }

*/

const GovernanceSection = ({ proposals }) => {
  return (
    <div>
      {Object.values(proposals).map((tokenProposals, i) => (
        <div className={styles.wrapper} key={i}>
          <TokenProposals tokenProposals={tokenProposals} />
        </div>
      ))}
    </div>
  )
}

export default GovernanceSection
