import { useState, useCallback, useEffect, useMemo } from 'react'
import { parseEther, formatEther } from 'ethers'
import BigNumber from 'bignumber.js'
import cx from 'classnames'

import { useBuyTokens, useBuyTokensEstimate } from 'hooks/useRealEstateToken'

import { useUserBalanceNative } from 'state/user/hooks'

import BuyTokenInput from 'components/UI/BuyTokenInput'

import { useIsCorrectAddress, useIsWalletConnected } from 'state/user/hooks'
import { useActiveNetwork } from 'state/network/hooks'

import { formatNative, fromDoubleEther } from 'utils/formatNumber'

import ErrorBuyBanner from 'components/UI/ErrorBuyBanner'

import styles from './styles.module.scss'
import SuccessBuyBanner from 'components/UI/SuccessBuyBanner'
import GlobalPreloader from 'components/UI/GlobalPreloader'

const BuyTokenForm = ({ tokenInfo, isRequestOwnerPurchase }) => {
  const activeNetwork = useActiveNetwork()
  const isCorrectAddress = useIsCorrectAddress()
  const isWalletConnected = useIsWalletConnected()

  const { loading, onBuyTokens } = useBuyTokens(tokenInfo.contract_address, tokenInfo.symbol)
  const onBuyTokensEstimate = useBuyTokensEstimate(tokenInfo.contract_address)
  const balanceNative = useUserBalanceNative()

  const [dnrAmount, setDnrAmount] = useState('')
  const [tokenAmount, setTokenAmount] = useState('')
  const [estimatedTxCost, setEstimatedTxCost] = useState(BigInt(0))

  const [formError, setFormError] = useState('')
  const [successPurchase, setSuccessPurchase] = useState(false)

  const [isTermsAccepted, setIsTermsAccepted] = useState(false)

  const toggleTerms = () => setIsTermsAccepted(!isTermsAccepted)

  const tokenPrice = BigInt(tokenInfo.price)
  const hardcap = BigInt(tokenInfo.hardcap)
  const totalSupply = BigInt(tokenInfo.total_supply)
  const maxBuyAmount = BigInt(tokenInfo.max_buy_amount)
  const minBuyAmount = BigInt(tokenInfo.min_buy_amount)
  const musharakahMinAmount = hardcap * 3n / 100n

  const availableTokens = hardcap - totalSupply

  function calcMaxAmountToBuy (maxLimit, availableTokens) {
    if (availableTokens <= maxLimit) {
      return availableTokens
    } else {
      return maxLimit
    }
  }

  const maxAvailableTokensForPurchase = calcMaxAmountToBuy(maxBuyAmount, availableTokens)

  let canBuy = useMemo(() => {
    setFormError('')
    if (tokenAmount === '' || tokenAmount === '0' || !tokenPrice || loading || !isCorrectAddress || !activeNetwork) {
      return false
    }

    if (isRequestOwnerPurchase && !isTermsAccepted) return false

    const amount = parseEther(tokenAmount)

    if (amount < minBuyAmount) {
      setFormError('Your amount is less than the minimum purchase quantity')
      return false
    }

    if (isRequestOwnerPurchase && amount < musharakahMinAmount) {
      setFormError('Your amount is less than the minimum purchase quantity')
      return false
    }

    if (maxAvailableTokensForPurchase > 0n && amount > maxAvailableTokensForPurchase) {
      setFormError('Your value is greater than the maximum purchase quantity')
      return false
    }
    console.log(balanceNative, estimatedTxCost)
    if (parseEther(dnrAmount) > balanceNative - estimatedTxCost) {
      setFormError('Insufficient balance to purchase tokens')
      return false
    }
    return true
  }, [
    dnrAmount,
    tokenAmount,
    balanceNative,
    estimatedTxCost,
    minBuyAmount,
    maxAvailableTokensForPurchase,
    maxBuyAmount,
    tokenPrice,
    musharakahMinAmount,
    isTermsAccepted,
    isRequestOwnerPurchase,
    loading,
  ])

  useEffect(() => {
    async function estimate() {
      if (
        !tokenAmount ||
        parseEther(tokenAmount) < minBuyAmount ||
        (maxAvailableTokensForPurchase > 0n && parseEther(tokenAmount) > maxAvailableTokensForPurchase) ||
        parseEther(dnrAmount) > BigInt(balanceNative)
      ) {
        setEstimatedTxCost(BigInt(0))
        return
      }
      const cost = await onBuyTokensEstimate(parseEther(dnrAmount || '0'), isRequestOwnerPurchase)
      setEstimatedTxCost(cost || 0n)
    }

    estimate()
  }, [
    dnrAmount,
    minBuyAmount,
    maxBuyAmount,
    maxAvailableTokensForPurchase,
    balanceNative,
    isRequestOwnerPurchase,
    onBuyTokensEstimate,
  ])

  const isValidNumberInput = (input) => {
    return input.match(/^\d*\.?\d*$/) != null && input !== '.'
  }

  const calculateDNRAmount = useCallback(amount => {
    if (!isValidNumberInput(amount)) {
      return
    }

    let validAmount = amount
    if (amount.endsWith('.')) {
      validAmount = amount.slice(0, -1)
    }

    const amt = new BigNumber(parseEther(validAmount)).div(new BigNumber(tokenPrice))
    const roundedAmt = new BigNumber(amt.toFixed(7, BigNumber.ROUND_DOWN))
    setDnrAmount(amount)
    setTokenAmount(roundedAmt.toString())
  }, [tokenPrice, balanceNative])

  const calculateTokenAmount = useCallback(amount => {
    if (!isValidNumberInput(amount)) {
      return
    }

    let validAmount = amount
    if (amount.endsWith('.')) {
      validAmount = amount.slice(0, -1)
    }

    const amt = new BigNumber(parseEther(validAmount)).times(new BigNumber(tokenPrice)).div(new BigNumber(1e36))
    const roundedAmt = new BigNumber(amt.toFixed(7, BigNumber.ROUND_DOWN))
    setTokenAmount(amount)
    setDnrAmount(roundedAmt.toString())
  }, [tokenPrice])

  const setMaxDnrAmount = useCallback(async () => {
    const cost = await onBuyTokensEstimate(BigInt(balanceNative))
    calculateDNRAmount(formatEther(BigInt(balanceNative) - cost))
  }, [balanceNative, calculateDNRAmount, onBuyTokensEstimate])

  const setMaxTokenAmount = useCallback(() => {
    calculateTokenAmount(formatEther(maxAvailableTokensForPurchase))
  }, [maxBuyAmount, maxAvailableTokensForPurchase, calculateTokenAmount])

  // const handleDNRAmountChange = useCallback(event => {
  //   const amount = event.target.value
  //   if (!event.target.validity.valid) {
  //     return
  //   }
  //   if (amount === '') {
  //     setDnrAmount('')
  //     setTokenAmount('')
  //     return
  //   }

  //   calculateDNRAmount(amount)
  // }, [calculateDNRAmount])

  // const handleTokenAmountChange = useCallback(event => {
  //   const amount = event.target.value
  //   if (!event.target.validity.valid) {
  //     return
  //   }

  //   if (amount === '') {
  //     setDnrAmount('')
  //     setTokenAmount('')
  //     return
  //   }

  //   calculateTokenAmount(amount)
  // }, [calculateTokenAmount])

  const handleDNRAmountChange = useCallback(event => {
    let { value } = event.target

    if (value === '.') {
      value = '0.'
    } else if (!/^\d*\.?\d*$/.test(value)) {
      return
    }

    if (value === '') {
      setDnrAmount('')
      setTokenAmount('')
    } else {
      setDnrAmount(value)
      calculateDNRAmount(value)
    }
  }, [calculateDNRAmount])

  const handleTokenAmountChange = useCallback(event => {
    let { value } = event.target

    if (value === '.') {
      value = '0.'
    } else if (!/^\d*\.?\d*$/.test(value)) {
      return
    }

    if (value === '') {
      setDnrAmount('')
      setTokenAmount('')
    } else {
      setTokenAmount(value)
      calculateTokenAmount(value)
    }
  }, [calculateTokenAmount])



  const handleBuyTokens = useCallback(async () => {

    if (isRequestOwnerPurchase && !isTermsAccepted) {
      setFormError('CHECKBOX')
      return
    }

    if (!canBuy) {
      return
    }

    try {
      const reciept = await onBuyTokens(parseEther(tokenAmount), tokenPrice, isRequestOwnerPurchase)
      if (reciept.status === 1) {
        // success transaction
        setSuccessPurchase(true)
      }
    }
    //Metamask error
    catch (error) {
      console.log(error)
    }
  }, [tokenAmount, tokenPrice, canBuy, isTermsAccepted, isRequestOwnerPurchase, onBuyTokens])

  const renderFirstError = () => {
    if (!isWalletConnected) {
      return <ErrorBuyBanner label="Wallet not connected" desc="" />
    }
    if (!isCorrectAddress) {
      return (
        <ErrorBuyBanner
          label="Your wallet address does not match your account"
          desc="When registering, each user adds his wallet address and only with the help of it can he perform all financial transactions. Please switch to your wallet address that you specified when registering in your Metamask."
        />
      )
    }
    if (!activeNetwork) {
      return (
        <ErrorBuyBanner
          label="You are using the wrong network"
          desc="Our application uses Edifice's own blockchain. Please switch to the Edifice network in your Metamask settings."
        />
      )
    }
    if ((balanceNative / tokenPrice <= fromDoubleEther(minBuyAmount)) && !isRequestOwnerPurchase) {
      return (
        <ErrorBuyBanner
          label="Insufficient funds for minimum purchase"
          desc={`The minimum quantity to purchase a ${tokenInfo.symbol} token is ${fromDoubleEther(minBuyAmount)}. Taking into account the price, you need an amount of ${fromDoubleEther(minBuyAmount) * fromDoubleEther(tokenPrice)} DNR to make a minimal purchase. You can buy more DNR at this page.`}
        />
      )
    }
    if ((balanceNative / tokenPrice <= fromDoubleEther(musharakahMinAmount)) && isRequestOwnerPurchase) {
      return (
        <ErrorBuyBanner
          label="Insufficient funds for minimum purchase"
          desc={`The minimum quantity to purchase a ${tokenInfo.symbol} token is ${formatNative(musharakahMinAmount)}. Taking into account the price, you need an amount of ${formatNative(musharakahMinAmount * fromDoubleEther(tokenPrice))} DNR to make a minimal purchase. You can buy more DNR at this page.`}
        />
      )
    }
    return null
  }

  return (
    <div className={styles.wrapper}>
      {loading && <GlobalPreloader />}
      { successPurchase && <SuccessBuyBanner text="The purchase of tokens was successful. They have been sent your wallet. You can check your balance both in Metamask and on the Wallet page" /> }
      <div className={styles.formIntit}>
        {' '}
        {renderFirstError()}
        <div className={styles.buyForm}>
          {isRequestOwnerPurchase ?
            (<div className={styles.becomeOwner}>
              By purchasing tokens, you are going to become the owner of this property. To do this, you need to agree to the terms of the Musharakah smart contract and its obligations, and also buy tokens worth at least 7% of the total supply
            </div>)
            :
            (<h4>
              Buying a
              {' '}
              <span>{tokenInfo.symbol}</span>
              {' '}
              token
            </h4>)}
          <p className={styles.description}></p>
          <div className={styles.priceBanner}>
            <div className={styles.additionalWrapper}>
              <p className={styles.price}>
                {`1 ${tokenInfo.symbol} = ${formatNative(tokenPrice)} DNR`}
              </p>
              <p className={styles.description}>Current price</p>
            </div>
            <div className={styles.additionalWrapper}>
              <p className={styles.price}>
                {`${formatNative(maxAvailableTokensForPurchase)} ${tokenInfo.symbol} = ${formatNative(fromDoubleEther(maxAvailableTokensForPurchase * tokenPrice)) } DNR`}
              </p>
              <p className={styles.description}>Maximum purchase</p>
            </div>
            <div className={styles.additionalWrapper}>
              {
                isRequestOwnerPurchase ?
                  (<p className={styles.price}>
                    {`${formatNative(musharakahMinAmount)} ${tokenInfo.symbol} = ${formatNative(fromDoubleEther(musharakahMinAmount * tokenPrice)) } DNR`}
                  </p>)
                  :
                  (<p className={styles.price}>
                    {`${formatNative(minBuyAmount)} ${tokenInfo.symbol} = ${formatNative(fromDoubleEther(minBuyAmount * tokenPrice)) } DNR`}
                  </p>)
              }
              <p className={styles.description}>Minimum purchase</p>
            </div>
            <hr />
          </div>
          <div className={styles.formWrapper}>
            <BuyTokenInput
              label="You pay"
              name="dnrAmount"
              value={dnrAmount}
              onChange={handleDNRAmountChange}
              staticLabelText="Your balance"
              actionLabelText={`${formatNative(balanceNative)} DNR`}
              actionLabelFunc={setMaxDnrAmount}
            />

            <BuyTokenInput
              label="You receive"
              name="tokenAmount"
              value={tokenAmount}
              onChange={handleTokenAmountChange}
              staticLabelText="Max limit"
              actionLabelText={formatNative(maxAvailableTokensForPurchase) + ' ' + tokenInfo.symbol}
              actionLabelFunc={setMaxTokenAmount}
            />
            {
              isRequestOwnerPurchase && <div className={styles.customCheckboxWrapper}>
                <label className={styles.labelWrapper}>
                  I agree to become the owner of the property and accept the conditions described in this document
                  {' '}
                  <a className={styles.termsLink} href="#">conditions for obtaining ownership rights</a>
                  <input className={styles.checkbox} checked={isTermsAccepted} value={isTermsAccepted} onChange={toggleTerms} type="checkbox" />
                  <span className={styles.checkmark}></span>
                </label>
              </div>
            }

            { formError && <p className={styles.formError}>{formError}</p>}
            <div className={styles.actionBtnWrapper} onClick={handleBuyTokens}>
              <p className={cx(styles.btn, {[styles.disabled]: !canBuy})}>
                {`Buy ${tokenInfo.symbol} token`}
              </p>
            </div>
            {/* { isCorrectAddress ? (<p className={styles.feeInfo}>Address correct</p>) : (<p className={styles.feeInfo}>Address not correct</p>) } */}
            <p className={styles.feeInfo}>
              {`Transaction fees: ~ ${formatNative(estimatedTxCost, 8)} DNR`}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BuyTokenForm
